import React from "react"
import Header from "../components/Header"
import { Wrapper } from "../components/styles/Container"
import GlobalStyles from "../components/styles/GlobalStyles"
import Button from "../components/styles/Button"
import SEO from "../components/SEO"

const Thanks = () => (
  <div>
    <GlobalStyles />
    <SEO title="Thanks" />
    <Header />
    <Wrapper style={{ marginTop: "6rem" }}>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ marginBottom: "3rem" }}>
          Thanks for your message! I'll contact you soon
        </h2>
        <Button link="/">Back</Button>
      </div>
    </Wrapper>
  </div>
)

export default Thanks
